@import '../../styles/_biocant-variables';
@import '../../styles/_mixins';

.loading{
  position: fixed;
  z-index: 999999;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-background-light;
  @include flexbox();
  @include flex(0 1 auto);
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);
  @include transition(opacity 0.5s);
  opacity: 1;

  &.fade-enter{
    opacity: 0;
  }

  &.fade-enter-done{
    opacity: 1;
  }

  &.fade-exit{
    opacity: 0;
  }

  .square {
    width: 40px;
    height: 40px;
    border: 4px solid $color-theme-1;
    animation: roll  1.5s infinite;
    transform-origin: center;
    outline: 1px solid transparent;
  }

  @keyframes roll {
    0% {
      transform: rotate(0);
    }
    50%, 100% {
      transform: rotate(90deg);
    }
  }
}

@media (min-width: 62em){
  .loading{
    top: 70px;
  }
}
