@import '../../styles/biocant-variables';

div.search {
  position: fixed;
  width: 100%;
  z-index: 21;
  background-color: $white;
  padding: 0;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: none;
  transition: height 200ms ease-in-out, padding 200ms ease-in-out,
    box-shadow 200ms ease-in-out;
  &.open {
    padding: 50px 0;
    height: calc(100vh - 70px);
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08);
  }
  &.open.no-results {
    height: 151px;
  }
  .container.center {
    max-width: 1198px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin: 0 auto;
  }
  .search-input {
    position: relative;
    display: flex;
    border-bottom: 1px solid rgba(140, 159, 175, 0.5);
    form {
      display: contents;
    }
    .icon-container {
      display: inline-block;
      width: 0;
      .search-icon {
        width: fit-content;
      }
    }
    input {
      flex: 1;
      padding: 3px 5px 13px 57px;
      border: none;
      background-color: transparent;
      font-family: Lato;
      font-size: 28px;
      letter-spacing: 0;
      line-height: 34px;
      opacity: 0.8;
      color: $color-text-dark;
      &::placeholder {
        color: rgba(140, 159, 175, 0.5);
      }
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }
    .close-button {
      padding: 6px;
      margin-right: 10px;
      border: none;
      background: none;
      cursor: pointer;
    }

    .enter-placeholder {
      padding: 16px 16px 0 0;
      background: none;
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;
      letter-spacing: 0;
      color: rgba(140, 159, 175, 0.5);
      cursor: default;
      img {
        vertical-align: middle;
        margin: 0 5px;
      }
    }
  }

  .result-list-container {
    width: 100%;
    height: fit-content;
    display: flex;
  }

  .result-list {
    position: relative;
    background-color: $white;
    z-index: 2;
    height: calc(100vh - 171px);
    padding-top: 50px;
    width: 100%;
    transition: height 200ms ease-in-out, padding-top 200ms ease-in-out;
    overflow: hidden;
    &.closed {
      height: 0;
      padding-top: 0;
    }
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 171px);
    background: transparent;
  }
  .loading {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    top: 200px;
    bottom: unset;
  }

  .message-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 171px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 28px;
      line-height: 26px;
      color: $color-text-dark;
    }
    p {
      margin-top: 20px;
      font-size: 20px;
      line-height: 26px;
      color: $dark-grey;
      opacity: 0.8;
    }
  }

  .scrollable {
    display: block;
    width: 100%;
    margin-top: 50px;
    height: 100%;
    max-width: 1198px;
    overflow: auto;
    margin: 0 auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e5e5e8;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #dadada;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #c0c0c0;
    }
  }

  .result-collection + .result-collection {
    margin-top: 60px;
  }

  h5.collection-name {
    font-size: 18px;
    line-height: 26px;
    color: $color-text-dark;
    padding: 0 20px 20px;
  }
  a.item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    line-height: 26px;
    color: $color-text-dark;
    background-color: $white;
    padding: 20px 20px 25px;

    .title {
      white-space: normal;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: $grey-light;
    }

    b.highlight {
      color: $color-theme-1;
      font-weight: bold;
    }

    .date {
      margin-top: 15px;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 2px;
      color: $color-text-grayed-light;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  a.item + a.item {
    border-top: 1px solid #e8e9f1;
  }
}

.close-search {
  display: none;
  position: fixed;
  z-index: 20;
  top: 100px;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  &.open {
    display: block;
  }
}

@media (max-width: $lg-size) {
  div.search {
    &.open {
      height: calc(100vh - 50px);
    }
  }
}

@media (max-width: $md-size) {
  div.search {
    position: absolute;
    &.open {
      padding: 34px 0;
      height: unset;
    }
    &.open.no-results {
      height: unset;
    }

    .search-input {
      .search-icon {
        width: 20px !important;
        height: 20px;
        margin-top: 4px;
        margin-left: 8px;
      }
      input {
        padding: 1px 0 20px 45px;
        font-size: 22px;
        line-height: 27px;
      }

      .close-button {
        margin-top: 1px;
        margin-bottom: 20px;
      }

      .enter-placeholder {
        display: none;
      }
    }

    .result-list {
      height: fit-content;
      min-height: calc(100vh - 135px);
      padding-top: 25px;
      transition: min-height 200ms ease-in-out;
      .date {
        margin-top: 10px;
      }
      &.closed {
        min-height: 0;
        padding-top: 0;
      }
    }
  }
}
