@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Cabin:400,400i,500,600,600i,700,700i);
.loading {
  position: fixed;
  z-index: 999999;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F9FAFB;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  justify-content: center;
  align-items: center;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  opacity: 1; }
  .loading.fade-enter {
    opacity: 0; }
  .loading.fade-enter-done {
    opacity: 1; }
  .loading.fade-exit {
    opacity: 0; }
  .loading .square {
    width: 40px;
    height: 40px;
    border: 4px solid #32C878;
    -webkit-animation: roll  1.5s infinite;
            animation: roll  1.5s infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
    outline: 1px solid transparent; }

@-webkit-keyframes roll {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  50%, 100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); } }

@keyframes roll {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  50%, 100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); } }

@media (min-width: 62em) {
  .loading {
    top: 70px; } }

.site-navbar {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  border-bottom: 1px solid #ededf8;
  z-index: 100000; }
  .site-navbar > .container,
  .site-navbar > .container > .row {
    height: 100%; }
  .site-navbar .logo {
    height: 52px;
    width: 269px; }
  .site-navbar .nav-menu-button,
  .site-navbar .i18n {
    display: none; }
  .site-navbar .search-btn {
    vertical-align: middle;
    cursor: pointer; }
    .site-navbar .search-btn img {
      padding: 0 5px; }
    .site-navbar .search-btn span {
      display: none; }

@media (max-width: 75em) {
  .site-navbar .logo {
    width: 183px; }
  .site-navbar .nav-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 100vw;
    background-color: #F9FAFB;
    padding-top: 56px;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: right 0.2 2s ease-in-out;
    transition: right 0.2 2s ease-in-out;
    z-index: -1; }
    .site-navbar .nav-wrapper:before {
      content: '';
      position: absolute;
      top: 56px;
      height: 1px;
      left: 0;
      right: 0;
      background-color: #ededf8; }
    .site-navbar .nav-wrapper.open {
      right: 0; }
      .site-navbar .nav-wrapper.open ~ .i18n {
        display: inline-flex;
        position: fixed;
        bottom: 30px;
        left: 45px; }
        .site-navbar .nav-wrapper.open ~ .i18n .lang {
          font-size: 14px;
          color: #ffffff;
          padding: 5px 5px;
          display: inline-block;
          font-family: "Lato", sans-serif;
          font-weight: bold; }
          .site-navbar .nav-wrapper.open ~ .i18n .lang:not(:first-child) {
            border-left: 1px solid rgba(140, 159, 175, 0.3); }
          .site-navbar .nav-wrapper.open ~ .i18n .lang .active {
            color: #32C878;
            border-radius: 4px;
            cursor: default; }
          .site-navbar .nav-wrapper.open ~ .i18n .lang span {
            padding: 4px; }
            .site-navbar .nav-wrapper.open ~ .i18n .lang span:not(.active):hover {
              background-color: #f8f9fa;
              border-radius: 4px;
              color: #8C9FAF;
              cursor: pointer; }
    .site-navbar .nav-wrapper .nav-list {
      text-align: left;
      padding-top: 40px;
      background-color: #1B212B;
      height: 100%; }
      .site-navbar .nav-wrapper .nav-list .nav-item {
        padding-left: 50px; }
        .site-navbar .nav-wrapper .nav-list .nav-item:not(:first-child) {
          margin-top: 20px; }
        .site-navbar .nav-wrapper .nav-list .nav-item a, .site-navbar .nav-wrapper .nav-list .nav-item span,
        .site-navbar .nav-wrapper .nav-list .nav-item .submenu-title {
          display: inline-block;
          position: relative;
          padding: 5px;
          left: -5px;
          font-size: 1rem;
          letter-spacing: 0.21px;
          line-height: 1;
          text-decoration: none;
          color: #ffffff;
          font-weight: bold;
          width: 100%; }
          .site-navbar .nav-wrapper .nav-list .nav-item a.active, .site-navbar .nav-wrapper .nav-list .nav-item span.active,
          .site-navbar .nav-wrapper .nav-list .nav-item .submenu-title.active {
            color: #32C878; }
          .site-navbar .nav-wrapper .nav-list .nav-item a:before, .site-navbar .nav-wrapper .nav-list .nav-item span:before,
          .site-navbar .nav-wrapper .nav-list .nav-item .submenu-title:before {
            content: '';
            position: absolute;
            background: url(/static/media/arrow.25a2981f.svg) no-repeat center center;
            background-size: contain;
            height: 20px;
            width: 20px;
            right: 10%;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .site-navbar .nav-menu-button {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    display: block;
    width: 24px;
    height: 24px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    cursor: pointer; }
    .site-navbar .nav-menu-button span {
      display: block;
      position: absolute;
      height: 2px;
      width: 50%;
      background: #1B212B;
      opacity: 1;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out; }
      .site-navbar .nav-menu-button span:nth-child(even) {
        left: 50%;
        border-radius: 0 20px 20px 0; }
      .site-navbar .nav-menu-button span:nth-child(odd) {
        left: 0;
        border-radius: 20px 0 0 20px; }
      .site-navbar .nav-menu-button span:nth-child(1), .site-navbar .nav-menu-button span:nth-child(2) {
        top: 0; }
      .site-navbar .nav-menu-button span:nth-child(3), .site-navbar .nav-menu-button span:nth-child(4) {
        top: 8px; }
      .site-navbar .nav-menu-button span:nth-child(5), .site-navbar .nav-menu-button span:nth-child(6) {
        top: 16px; }
    .site-navbar .nav-menu-button.open span:nth-child(1), .site-navbar .nav-menu-button.open span:nth-child(6) {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    .site-navbar .nav-menu-button.open span:nth-child(2), .site-navbar .nav-menu-button.open span:nth-child(5) {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    .site-navbar .nav-menu-button.open span:nth-child(1) {
      left: 0;
      top: 5px; }
    .site-navbar .nav-menu-button.open span:nth-child(2) {
      left: calc(50% - 4px);
      top: 5px; }
    .site-navbar .nav-menu-button.open span:nth-child(3) {
      left: -50%;
      opacity: 0; }
    .site-navbar .nav-menu-button.open span:nth-child(4) {
      left: 100%;
      opacity: 0; }
    .site-navbar .nav-menu-button.open span:nth-child(5) {
      left: 0;
      top: 13px; }
    .site-navbar .nav-menu-button.open span:nth-child(6) {
      left: calc(50% - 4px);
      top: 13px; }
  .search-btn img {
    position: absolute;
    -webkit-filter: brightness(2);
            filter: brightness(2);
    padding: 5px 0 0 !important; }
  .search-btn span {
    padding-left: 35px !important; } }

@media (min-width: 75em) {
  .site-navbar {
    height: 70px; }
    .site-navbar .nav-wrapper .nav-list > .nav-item {
      display: inline-block;
      margin-right: 15px;
      position: relative; }
      .site-navbar .nav-wrapper .nav-list > .nav-item a, .site-navbar .nav-wrapper .nav-list > .nav-item span,
      .site-navbar .nav-wrapper .nav-list > .nav-item .submenu-title {
        font-size: 0.938rem;
        font-weight: 400;
        letter-spacing: 0.2px;
        line-height: 1.533;
        color: #8C9FAF;
        text-decoration: none;
        vertical-align: middle;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .site-navbar .nav-wrapper .nav-list > .nav-item a.active, .site-navbar .nav-wrapper .nav-list > .nav-item a.hasActiveChild, .site-navbar .nav-wrapper .nav-list > .nav-item span.active, .site-navbar .nav-wrapper .nav-list > .nav-item span.hasActiveChild,
        .site-navbar .nav-wrapper .nav-list > .nav-item .submenu-title.active,
        .site-navbar .nav-wrapper .nav-list > .nav-item .submenu-title.hasActiveChild {
          color: #32C878; }
      .site-navbar .nav-wrapper .nav-list > .nav-item a:hover:after,
      .site-navbar .nav-wrapper .nav-list > .nav-item .submenu-title:hover:after, .site-navbar .nav-wrapper .nav-list > .nav-item .submenu-title.open:after {
        background-color: #8C9FAF; }
      .site-navbar .nav-wrapper .nav-list > .nav-item a.active:after,
      .site-navbar .nav-wrapper .nav-list > .nav-item .submenu-title.hasActiveChild:after {
        background-color: #32C878; }
      .site-navbar .nav-wrapper .nav-list > .nav-item .submenu-title {
        cursor: pointer; }
        .site-navbar .nav-wrapper .nav-list > .nav-item .submenu-title img {
          margin-left: 5px;
          vertical-align: middle; }
      .site-navbar .nav-wrapper .nav-list > .nav-item .collapse-wrapper {
        position: absolute;
        top: calc(100% + 25px);
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%); }
        .site-navbar .nav-wrapper .nav-list > .nav-item .collapse-wrapper .ReactCollapse--content {
          padding: 0 10px 10px 10px; }
      .site-navbar .nav-wrapper .nav-list > .nav-item .submenu-wrapper {
        padding-top: 15px; }
      .site-navbar .nav-wrapper .nav-list > .nav-item .submenu {
        background-color: #F9FAFB;
        padding: 10px 7px;
        min-width: 120px;
        width: -moz-max-content;
        width: -webkit-max-content;
        width: max-content;
        text-align: left;
        box-shadow: 0 2px 6px 0 rgba(192, 196, 215, 0.4);
        position: relative; }
        .site-navbar .nav-wrapper .nav-list > .nav-item .submenu:before, .site-navbar .nav-wrapper .nav-list > .nav-item .submenu:after {
          content: '';
          position: absolute;
          top: -3px;
          left: 50%;
          width: 21px;
          height: 21px;
          -webkit-transform: rotate(45deg) translateX(-50%);
          transform: rotate(45deg) translateX(-50%); }
        .site-navbar .nav-wrapper .nav-list > .nav-item .submenu:before {
          box-shadow: 0 2px 6px 0 rgba(192, 196, 215, 0.4);
          z-index: -1; }
        .site-navbar .nav-wrapper .nav-list > .nav-item .submenu:after {
          background: #F9FAFB; }
        .site-navbar .nav-wrapper .nav-list > .nav-item .submenu .nav-item:not(:last-child) {
          margin-bottom: 5px; }
        .site-navbar .nav-wrapper .nav-list > .nav-item .submenu .nav-item a {
          display: block;
          position: relative;
          z-index: 2;
          font-size: 0.938rem;
          font-weight: 400;
          letter-spacing: 0.1px;
          line-height: 1.533;
          color: #767676;
          text-decoration: none;
          padding: 5px 13px;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          background-color: #F9FAFB;
          border-radius: 2px; }
          .site-navbar .nav-wrapper .nav-list > .nav-item .submenu .nav-item a:hover {
            background-color: rgba(234, 237, 243, 0.45); }
          .site-navbar .nav-wrapper .nav-list > .nav-item .submenu .nav-item a.active {
            color: #484848;
            font-weight: 600; }
    .site-navbar .i18n {
      display: block;
      position: relative; }
      .site-navbar .i18n .lang {
        font-size: 14px;
        color: #8C9FAF;
        padding: 5px 10px;
        display: inline-block;
        font-family: "Lato", sans-serif; }
        .site-navbar .i18n .lang:not(:first-child) {
          border-left: 1px solid rgba(140, 159, 175, 0.3); }
        .site-navbar .i18n .lang .active {
          background-color: #32C878;
          border-radius: 4px;
          color: #ffffff;
          cursor: default; }
        .site-navbar .i18n .lang span {
          padding: 4px; }
          .site-navbar .i18n .lang span:not(.active):hover {
            background-color: #f8f9fa;
            border-radius: 4px;
            color: #8C9FAF;
            cursor: pointer; }
      .site-navbar .i18n .select__control {
        border: none;
        background: none;
        min-height: auto;
        cursor: pointer;
        padding: 5px; }
        .site-navbar .i18n .select__control.select__control--is-focused {
          box-shadow: none; }
        .site-navbar .i18n .select__control .select__indicators {
          display: none; }
        .site-navbar .i18n .select__control .select__value-container {
          padding: 0;
          width: 30px;
          height: 30px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 24px 24px; }
          .site-navbar .i18n .select__control .select__value-container .select__single-value {
            display: none; }
      .site-navbar .i18n .select__menu {
        box-shadow: 0 2px 6px 0 rgba(192, 196, 215, 0.2);
        background: #F9FAFB;
        border: none;
        margin-top: 30px;
        width: 120px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%); }
        .site-navbar .i18n .select__menu:before {
          content: '';
          position: absolute;
          top: -3px;
          left: 50%;
          width: 21px;
          height: 21px;
          background: #F9FAFB;
          -webkit-transform: rotate(45deg) translateX(-50%);
          transform: rotate(45deg) translateX(-50%);
          box-shadow: 0 2px 6px 0 rgba(192, 196, 215, 0.2); }
        .site-navbar .i18n .select__menu .select__menu-list {
          padding-left: 4px;
          padding-right: 4px;
          background: #F9FAFB;
          text-align: left; }
          .site-navbar .i18n .select__menu .select__menu-list .select__option {
            padding: 5px 20px 6px 14px;
            background-color: transparent;
            font-size: 0.938rem;
            font-weight: 400;
            line-height: 1.533;
            letter-spacing: 0.1px;
            color: #767676;
            border-radius: 2px;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            cursor: pointer; }
            .site-navbar .i18n .select__menu .select__menu-list .select__option:hover, .site-navbar .i18n .select__menu .select__menu-list .select__option.select__option--is-focused {
              background-color: rgba(234, 237, 243, 0.45); }
            .site-navbar .i18n .select__menu .select__menu-list .select__option.select__option--is-selected {
              font-weight: 600;
              color: #484848; } }

@media (min-width: 72em) {
  .site-navbar .nav-wrapper .nav-list > .nav-item {
    margin-right: 30px; } }

.site-footer {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 45px 0 0;
  border-top: 1px solid #ededf8;
  background-color: #ffffff;
  text-align: left; }
  .site-footer .logo {
    background: url(/static/media/biocant_logo.89af3ef8.svg) no-repeat center center;
    background-size: contain;
    height: 50px;
    width: 200px; }
  .site-footer .column-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    letter-spacing: normal;
    color: #1B212B;
    margin-bottom: 6px; }
  .site-footer .navigation {
    margin-top: 42px; }
  .site-footer .addresses,
  .site-footer .phones {
    margin-top: 28px; }
  .site-footer .navigation-item a,
  .site-footer .address-item p,
  .site-footer .phone-item a,
  .site-footer .external-links a {
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: none;
    padding-left: 5px;
    color: #8C9FAF;
    position: relative;
    left: -5px;
    display: inline-block; }
  .site-footer .navigation-item a,
  .site-footer .phone-item a,
  .site-footer .external-links a {
    padding: 5px;
    line-height: 1.3; }
  .site-footer .navigation-item a,
  .site-footer .phone-item a,
  .site-footer .external-links a {
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
    .site-footer .navigation-item a:hover,
    .site-footer .phone-item a:hover,
    .site-footer .external-links a:hover {
      color: #1B212B; }
  .site-footer .address-item {
    line-height: 20px;
    font-size: 14px; }
    .site-footer .address-item .see-map {
      color: #32C878;
      font-size: 13px;
      line-height: 19px; }
      .site-footer .address-item .see-map:hover {
        cursor: pointer; }
  .site-footer .address-item p {
    white-space: pre-line; }
  .site-footer .privacy-and-terms p,
  .site-footer .privacy-and-terms a {
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: normal;
    font-weight: 400;
    color: #8C9FAF; }
  .site-footer .privacy-and-terms a {
    color: #1B212B;
    text-decoration: none; }
  .site-footer .faqs {
    margin-bottom: 15px; }
  .site-footer .social-links {
    white-space: nowrap;
    margin-top: 15px; }
    .site-footer .social-links .social-link-item {
      display: inline-block; }
      .site-footer .social-links .social-link-item:not(:first-child) {
        margin-left: 10px; }
      .site-footer .social-links .social-link-item a,
      .site-footer .social-links .social-link-item img {
        display: block; }
      .site-footer .social-links .social-link-item .image {
        width: 15px;
        height: 15px;
        display: block;
        fill: #8C9FAF; }
        .site-footer .social-links .social-link-item .image:hover {
          fill: #000000; }
  .site-footer .external-book-links img {
    height: 50px;
    margin-top: 22px; }
  .site-footer .external-book-links a:last-child img {
    margin-top: 8px; }
  .site-footer [class^='col-xs'],
  .site-footer [class^='col-sm'],
  .site-footer [class^='col-md'],
  .site-footer [class^='col-lg'] {
    display: block; }

@media (min-width: 62em) {
  .site-footer {
    padding: 50px 0;
    position: relative; }
    .site-footer .logo-section {
      text-align: left; }
    .site-footer .navigation-item a,
    .site-footer .phone-item a {
      line-height: 1.4; }
    .site-footer .navigation-item a {
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
    .site-footer .address-item p,
    .site-footer .privacy-and-terms p,
    .site-footer .faqs p {
      line-height: 13px; }
      .site-footer .address-item p:first-child,
      .site-footer .privacy-and-terms p:first-child,
      .site-footer .faqs p:first-child {
        margin-top: 5px; }
    .site-footer .i18n,
    .site-footer .navigation,
    .site-footer .addresses,
    .site-footer .phones {
      margin-top: 0; }
    .site-footer .i18n {
      margin-right: -10px;
      margin-top: -10px;
      margin-bottom: 0;
      margin-left: auto; }
      .site-footer .i18n .select__control .select__value-container {
        -moz-justify-content: flex-end;
        justify-content: flex-end; }
    .site-footer .faqs {
      margin-bottom: 9px; }
    .site-footer hr {
      height: 30px; }
    .site-footer .copyright {
      margin-bottom: 36px; }
    .site-footer .footer-illustration {
      margin: 0;
      float: none;
      position: absolute;
      bottom: 0;
      right: 0; } }

@media (max-width: 991px) {
  .site-footer .footer-illustration-sector {
    margin-top: -125px; }
  .site-footer .gutterless {
    margin-top: 30px; }
  .site-footer .logo {
    margin-left: auto;
    margin-right: auto; }
  .site-footer .navigation-col .navigation {
    margin-top: 0; }
    .site-footer .navigation-col .navigation .column-title {
      display: none; }
    .site-footer .navigation-col .navigation .navigation-list .navigation-item {
      margin-bottom: 20px; }
  .site-footer .contacts-col,
  .site-footer .addresses-col {
    display: none; }
  .site-footer .follow-us-col {
    padding-bottom: 50px; }
    .site-footer .follow-us-col .follow-us .column-title {
      margin: 20px 0; }
    .site-footer .follow-us-col .social-links .social-link-item {
      border: 0.5px solid #8C9FAF;
      border-radius: 50%; }
      .site-footer .follow-us-col .social-links .social-link-item .image {
        margin: 20px; }
  .site-footer .external-book-links {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    align-items: flex-start;
    margin-top: 14px; } }

@media (max-width: 491px) {
  .site-footer .footer-illustration-sector {
    margin-top: 0; } }

@-webkit-keyframes partnersSliderTimer {
  0% {
    stroke-dashoffset: 119.3808; }
  to {
    stroke-dashoffset: 0; } }

@keyframes partnersSliderTimer {
  0% {
    stroke-dashoffset: 119.3808; }
  to {
    stroke-dashoffset: 0; } }

@-webkit-keyframes partnersSliderTimerRevert {
  0% {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: -119.3808; } }

@keyframes partnersSliderTimerRevert {
  0% {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: -119.3808; } }

@-webkit-keyframes moveImage {
  0% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  25% {
    -webkit-transform: translate3d(-10px, -10px, 25px);
    transform: translate3d(-20px, -20px, 25px); }
  50% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(40px, 40px, 50px); }
  75% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(-20px, -20px, 25px); }
  100% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0px, 0px, 0px); } }

@keyframes moveImage {
  0% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  25% {
    -webkit-transform: translate3d(-10px, -10px, 25px);
    transform: translate3d(-20px, -20px, 25px); }
  50% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(40px, 40px, 50px); }
  75% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(-20px, -20px, 25px); }
  100% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0px, 0px, 0px); } }

@-webkit-keyframes openPage {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes openPage {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-75px);
    transform: translateX(-75px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-75px);
    transform: translateX(-75px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1; } }

@-webkit-keyframes fadeInLater {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1; } }

@keyframes fadeInLater {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1; } }

@-webkit-keyframes slide {
  100% {
    left: 0; } }

@keyframes slide {
  100% {
    left: 0; } }

a {
  text-decoration: none; }

.btn {
  display: inline-block;
  font-family: "Cabin", sans-serif;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold; }
  .btn:hover {
    opacity: 1; }
  .btn:disabled {
    background-color: #dae0e5;
    border-color: #dae0e5;
    color: #ffffff;
    pointer-events: none; }

.btn-primary {
  background-color: #32C878;
  border-color: transparent;
  -webkit-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  padding: 14px 20px;
  color: #ffffff; }
  .btn-primary:hover {
    cursor: pointer;
    background-color: #5BD393; }
  .btn-primary:disabled {
    background-color: #dae0e5;
    color: #ffffff;
    box-shadow: none; }

.not-found-wrapper {
  background-color: #05B76E;
  text-align: center; }
  .not-found-wrapper .not-found-content {
    height: 100%;
    width: 100%;
    background: url("/static/media/DAN08-201D-6397 2.0151ea29.png") no-repeat center center;
    background-size: cover;
    padding: 200px 0; }
  .not-found-wrapper img {
    width: 100%;
    height: 160px; }
  .not-found-wrapper .title {
    color: #5BD393;
    font-size: 50px;
    line-height: 63px;
    text-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
    font-weight: bold; }
  .not-found-wrapper .message {
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
    line-height: 32px; }
  .not-found-wrapper button {
    background-color: #ffffff;
    color: #32C878;
    margin-top: 20px; }

div.search {
  position: fixed;
  width: 100%;
  z-index: 21;
  background-color: #ffffff;
  padding: 0;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: none;
  -webkit-transition: height 200ms ease-in-out, padding 200ms ease-in-out, box-shadow 200ms ease-in-out;
  transition: height 200ms ease-in-out, padding 200ms ease-in-out, box-shadow 200ms ease-in-out; }
  div.search.open {
    padding: 50px 0;
    height: calc(100vh - 70px);
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08); }
  div.search.open.no-results {
    height: 151px; }
  div.search .container.center {
    max-width: 1198px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin: 0 auto; }
  div.search .search-input {
    position: relative;
    display: flex;
    border-bottom: 1px solid rgba(140, 159, 175, 0.5); }
    div.search .search-input form {
      display: contents; }
    div.search .search-input .icon-container {
      display: inline-block;
      width: 0; }
      div.search .search-input .icon-container .search-icon {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
    div.search .search-input input {
      flex: 1 1;
      padding: 3px 5px 13px 57px;
      border: none;
      background-color: transparent;
      font-family: Lato;
      font-size: 28px;
      letter-spacing: 0;
      line-height: 34px;
      opacity: 0.8;
      color: #1B212B; }
      div.search .search-input input::-webkit-input-placeholder {
        color: rgba(140, 159, 175, 0.5); }
      div.search .search-input input:-ms-input-placeholder {
        color: rgba(140, 159, 175, 0.5); }
      div.search .search-input input::-ms-input-placeholder {
        color: rgba(140, 159, 175, 0.5); }
      div.search .search-input input::placeholder {
        color: rgba(140, 159, 175, 0.5); }
      div.search .search-input input::-webkit-search-decoration, div.search .search-input input::-webkit-search-cancel-button, div.search .search-input input::-webkit-search-results-button, div.search .search-input input::-webkit-search-results-decoration {
        -webkit-appearance: none; }
    div.search .search-input .close-button {
      padding: 6px;
      margin-right: 10px;
      border: none;
      background: none;
      cursor: pointer; }
    div.search .search-input .enter-placeholder {
      padding: 16px 16px 0 0;
      background: none;
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;
      letter-spacing: 0;
      color: rgba(140, 159, 175, 0.5);
      cursor: default; }
      div.search .search-input .enter-placeholder img {
        vertical-align: middle;
        margin: 0 5px; }
  div.search .result-list-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex; }
  div.search .result-list {
    position: relative;
    background-color: #ffffff;
    z-index: 2;
    height: calc(100vh - 171px);
    padding-top: 50px;
    width: 100%;
    -webkit-transition: height 200ms ease-in-out, padding-top 200ms ease-in-out;
    transition: height 200ms ease-in-out, padding-top 200ms ease-in-out;
    overflow: hidden; }
    div.search .result-list.closed {
      height: 0;
      padding-top: 0; }
  div.search .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 171px);
    background: transparent; }
  div.search .loading {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    top: 200px;
    bottom: unset; }
  div.search .message-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 171px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    div.search .message-container h4 {
      font-size: 28px;
      line-height: 26px;
      color: #1B212B; }
    div.search .message-container p {
      margin-top: 20px;
      font-size: 20px;
      line-height: 26px;
      color: #62707C;
      opacity: 0.8; }
  div.search .scrollable {
    display: block;
    width: 100%;
    margin-top: 50px;
    height: 100%;
    max-width: 1198px;
    overflow: auto;
    margin: 0 auto; }
    div.search .scrollable::-webkit-scrollbar {
      width: 8px; }
    div.search .scrollable::-webkit-scrollbar-thumb {
      background: #e5e5e8;
      border-radius: 4px; }
    div.search .scrollable::-webkit-scrollbar-thumb:hover {
      background: #dadada; }
    div.search .scrollable::-webkit-scrollbar-thumb:active {
      background: #c0c0c0; }
  div.search .result-collection + .result-collection {
    margin-top: 60px; }
  div.search h5.collection-name {
    font-size: 18px;
    line-height: 26px;
    color: #1B212B;
    padding: 0 20px 20px; }
  div.search a.item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    line-height: 26px;
    color: #1B212B;
    background-color: #ffffff;
    padding: 20px 20px 25px; }
    div.search a.item .title {
      white-space: normal;
      text-overflow: ellipsis; }
    div.search a.item:hover {
      background-color: #f8f9fa; }
    div.search a.item b.highlight {
      color: #32C878;
      font-weight: bold; }
    div.search a.item .date {
      margin-top: 15px;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 2px;
      color: #8C9FAF;
      font-weight: bold;
      text-transform: uppercase; }
  div.search a.item + a.item {
    border-top: 1px solid #e8e9f1; }

.close-search {
  display: none;
  position: fixed;
  z-index: 20;
  top: 100px;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer; }
  .close-search.open {
    display: block; }

@media (max-width: 75em) {
  div.search.open {
    height: calc(100vh - 50px); } }

@media (max-width: 62em) {
  div.search {
    position: absolute; }
    div.search.open {
      padding: 34px 0;
      height: unset; }
    div.search.open.no-results {
      height: unset; }
    div.search .search-input .search-icon {
      width: 20px !important;
      height: 20px;
      margin-top: 4px;
      margin-left: 8px; }
    div.search .search-input input {
      padding: 1px 0 20px 45px;
      font-size: 22px;
      line-height: 27px; }
    div.search .search-input .close-button {
      margin-top: 1px;
      margin-bottom: 20px; }
    div.search .search-input .enter-placeholder {
      display: none; }
    div.search .result-list {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      min-height: calc(100vh - 135px);
      padding-top: 25px;
      -webkit-transition: min-height 200ms ease-in-out;
      transition: min-height 200ms ease-in-out; }
      div.search .result-list .date {
        margin-top: 10px; }
      div.search .result-list.closed {
        min-height: 0;
        padding-top: 0; } }

/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  font: inherit; }

:focus {
  outline: none; }

/* Uncomment and set these variables to customize the grid. */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.row.reverse {
  flex-direction: row-reverse; }

.col.reverse {
  flex-direction: column-reverse; }

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem; }

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  flex-basis: 8.333%;
  max-width: 8.333%; }

.col-xs-2 {
  flex-basis: 16.667%;
  max-width: 16.667%; }

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  flex-basis: 33.333%;
  max-width: 33.333%; }

.col-xs-5 {
  flex-basis: 41.667%;
  max-width: 41.667%; }

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  flex-basis: 58.333%;
  max-width: 58.333%; }

.col-xs-8 {
  flex-basis: 66.667%;
  max-width: 66.667%; }

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  flex-basis: 83.333%;
  max-width: 83.333%; }

.col-xs-11 {
  flex-basis: 91.667%;
  max-width: 91.667%; }

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  margin-left: 8.333%; }

.col-xs-offset-2 {
  margin-left: 16.667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.333%; }

.col-xs-offset-5 {
  margin-left: 41.667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.333%; }

.col-xs-offset-8 {
  margin-left: 66.667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.333%; }

.col-xs-offset-11 {
  margin-left: 91.667%; }

.start-xs {
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  justify-content: center;
  text-align: center; }

.end-xs {
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.bottom-xs {
  align-items: flex-end; }

.around-xs {
  justify-content: space-around; }

.between-xs {
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem; }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-sm-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-sm-2 {
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-sm-5 {
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-sm-8 {
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-sm-11 {
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    margin-left: 8.333%; }
  .col-sm-offset-2 {
    margin-left: 16.667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.333%; }
  .col-sm-offset-5 {
    margin-left: 41.667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.333%; }
  .col-sm-offset-8 {
    margin-left: 66.667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.333%; }
  .col-sm-offset-11 {
    margin-left: 91.667%; }
  .start-sm {
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    justify-content: center;
    text-align: center; }
  .end-sm {
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    align-items: flex-start; }
  .middle-sm {
    align-items: center; }
  .bottom-sm {
    align-items: flex-end; }
  .around-sm {
    justify-content: space-around; }
  .between-sm {
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem; }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-md-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-md-2 {
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-md-5 {
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-md-8 {
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-md-11 {
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    margin-left: 8.333%; }
  .col-md-offset-2 {
    margin-left: 16.667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.333%; }
  .col-md-offset-5 {
    margin-left: 41.667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.333%; }
  .col-md-offset-8 {
    margin-left: 66.667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.333%; }
  .col-md-offset-11 {
    margin-left: 91.667%; }
  .start-md {
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    justify-content: center;
    text-align: center; }
  .end-md {
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    align-items: flex-start; }
  .middle-md {
    align-items: center; }
  .bottom-md {
    align-items: flex-end; }
  .around-md {
    justify-content: space-around; }
  .between-md {
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem; }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-lg-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-lg-2 {
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-lg-5 {
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-lg-8 {
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-lg-11 {
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    margin-left: 8.333%; }
  .col-lg-offset-2 {
    margin-left: 16.667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.333%; }
  .col-lg-offset-5 {
    margin-left: 41.667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.333%; }
  .col-lg-offset-8 {
    margin-left: 66.667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.333%; }
  .col-lg-offset-11 {
    margin-left: 91.667%; }
  .start-lg {
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    justify-content: center;
    text-align: center; }
  .end-lg {
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    align-items: flex-start; }
  .middle-lg {
    align-items: center; }
  .bottom-lg {
    align-items: flex-end; }
  .around-lg {
    justify-content: space-around; }
  .between-lg {
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

.container {
  width: 100%;
  max-width: 1224px; }

.container-sm {
  max-width: 760px; }

.container-fluid {
  padding-right: 1rem;
  padding-left: 1rem; }

.col {
  flex-direction: column; }

.gutterless {
  padding-left: 0;
  padding-right: 0; }
  .gutterless > .row {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }

[class^="col-xs"],
[class^="col-sm"],
[class^="col-md"],
[class^="col-lg"] {
  display: flex; }

h1, h2, h3, h4, h5, h6, small {
  font-family: "Lato", sans-serif;
  font-weight: 400; }

h1 {
  font-size: 60px;
  line-height: 40px;
  font-weight: 400; }

h2 {
  font-size: 50px;
  line-height: 63px; }

h3 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 400; }

h4 {
  font-size: 35px;
  line-height: 50px;
  font-weight: 700; }

h5 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 700; }

h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px; }

p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400; }

small {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400; }

strong {
  font-weight: bold; }

@media (max-width: 48em) {
  h1, h2 {
    font-family: "Cabin", sans-serif; }
  h1 {
    font-size: 38px;
    line-height: 49.4px;
    font-weight: bold;
    color: #ffffff; }
  h2 {
    font-size: 36px;
    line-height: 55px; }
  h3 {
    font-size: 30px;
    line-height: 50px; }
  h4 {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold; } }

@-webkit-keyframes partnersSliderTimer {
  0% {
    stroke-dashoffset: 119.3808; }
  to {
    stroke-dashoffset: 0; } }

@keyframes partnersSliderTimer {
  0% {
    stroke-dashoffset: 119.3808; }
  to {
    stroke-dashoffset: 0; } }

@-webkit-keyframes partnersSliderTimerRevert {
  0% {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: -119.3808; } }

@keyframes partnersSliderTimerRevert {
  0% {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: -119.3808; } }

@-webkit-keyframes moveImage {
  0% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  25% {
    -webkit-transform: translate3d(-10px, -10px, 25px);
    transform: translate3d(-20px, -20px, 25px); }
  50% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(40px, 40px, 50px); }
  75% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(-20px, -20px, 25px); }
  100% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0px, 0px, 0px); } }

@keyframes moveImage {
  0% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  25% {
    -webkit-transform: translate3d(-10px, -10px, 25px);
    transform: translate3d(-20px, -20px, 25px); }
  50% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(40px, 40px, 50px); }
  75% {
    -webkit-transform: translate3d(10px, 10px, 50px);
    transform: translate3d(-20px, -20px, 25px); }
  100% {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0px, 0px, 0px); } }

@-webkit-keyframes openPage {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes openPage {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-75px);
    transform: translateX(-75px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-75px);
    transform: translateX(-75px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1; } }

@-webkit-keyframes fadeInLater {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1; } }

@keyframes fadeInLater {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1; } }

@-webkit-keyframes slide {
  100% {
    left: 0; } }

@keyframes slide {
  100% {
    left: 0; } }

body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

* {
  box-sizing: border-box; }

.relative {
  position: relative; }

.app-shell {
  background-color: #F9FAFB;
  overflow: hidden; }
  .app-shell.freeze {
    height: 100vh; }

.page-wrapper {
  padding-top: 56px;
  background-color: #ffffff;
  overflow: hidden;
  min-height: calc(100vh - 745px); }
  .page-wrapper > :first-child {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-name: openPage;
            animation-name: openPage; }

.ellipsis-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.loading img {
  height: 120px;
  width: 105px; }

@media only screen and (min-width: 48em) {
  .ellipsis-line-sm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

@media (min-width: 75em) {
  .page-wrapper {
    padding-top: 70px;
    min-height: calc(100vh - 282px); } }

