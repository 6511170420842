@import '../../styles/_biocant-variables';
@import '../../styles/_mixins';
@import '../../styles/_animations';
a{
  text-decoration: none;
}

.btn {
  display: inline-block;
  font-family: $font-family-secondary;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    background-color: #dae0e5;
    border-color: #dae0e5;
    color: $white;
    pointer-events: none;
  }
}

.btn-primary {
  background-color: $color-theme-1;
  border-color: transparent;
  transition: background-color 100ms linear;
  padding: 14px 20px;
  color: $white;

  &:hover {
    cursor: pointer;
    background-color: $green-light;
  }

  &:disabled {
    background-color: #dae0e5;
    color: $white;
    box-shadow: none;
  }
}
