@import '../styles/_biocant-variables';
@import '../styles/_mixins';


.site-footer {
  user-select: none;
  padding: 45px 0 0;
  border-top: 1px solid $color-background-grayed;
  background-color: $white;
  text-align: left;

  .logo {
    background: url("../assets/biocant_logo.svg") no-repeat center center;
    background-size: contain;
    height: 50px;
    width: 200px;
  }

  .column-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    letter-spacing: normal;
    color: $color-text-dark;
    margin-bottom: 6px;
  }

  .navigation {
    margin-top: 42px;
  }

  .addresses,
  .phones {
    margin-top: 28px;
  }

  .navigation-item a,
  .address-item p,
  .phone-item a,
  .external-links a{
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: none;
    padding-left: 5px;
    color: $color-text-grayed-light;
    position: relative;
    left: -5px;
    display: inline-block;
  }

  .navigation-item a,
  .phone-item a ,
  .external-links a{
    padding: 5px;
    line-height: 1.3;
  }

  .navigation-item a,
  .phone-item a,
  .external-links a{
    @include transition(all 0.5s);

    &:hover {
      color: $color-text-dark;
    }
  }

  .address-item{
    line-height: 20px;
    font-size: 14px;
    .see-map{
      color: $color-theme-1;
      font-size: 13px;
      line-height: 19px;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .address-item p {
    white-space: pre-line;
  }

  .privacy-and-terms
  {
    p,
    a {
      font-size: 0.875rem;
      line-height: 1.5;
      letter-spacing: normal;
      font-weight: 400;
      color: $color-text-grayed-light;
    }

    a {
      color: $color-text-dark;
      text-decoration: none;
    }
  }

  .faqs {
    margin-bottom: 15px;
  }

  .social-links {
    white-space: nowrap;
    margin-top: 15px;

    .social-link-item {
      display: inline-block;

      &:not(:first-child) {
        margin-left: 10px;
      }

      a,
      img {
        display: block;
      }
      .image{
          width: 15px;
          height: 15px;
          display: block;
          fill: $color-text-grayed-light;
        &:hover{
          fill: $black;
        }
      }
    }
  }

  .external-book-links {
    img {
      height: 50px;
      margin-top: 22px;
    }
    & a:last-child img {
      margin-top: 8px;
    }
  }

  [class^='col-xs'],
  [class^='col-sm'],
  [class^='col-md'],
  [class^='col-lg'] {
    display: block;
  }
}

@media (min-width: 62em) {
  .site-footer {
    padding: 50px 0 ;
    position: relative;

    .logo-section{
      text-align: left;
    }
    .navigation-item a,
    .phone-item a {
      line-height: 1.4;
    }

    .navigation-item a {
      width: max-content;
    }

    .address-item p,
    .privacy-and-terms p,
    .faqs p {
      line-height: 13px;
      &:first-child{
      margin-top: 5px;
      }
    }

    .i18n,
    .navigation,
    .addresses,
    .phones {
      margin-top: 0;
    }

    .i18n {
      margin-right: -10px;
      margin-top: -10px;
      margin-bottom: 0;
      margin-left: auto;

      .select__control {
        .select__value-container {
          @include justify-content(flex-end);
        }
      }
    }

    .faqs {
      margin-bottom: 9px;
    }

    hr {
      height: 30px;
    }

    .copyright {
      margin-bottom: 36px;
    }

    .footer-illustration {
      margin: 0;
      float: none;
      position: absolute;
      bottom: 0;
      right: 0;
    }

  }
}


@media (max-width: 991px) {
  .site-footer {
    .footer-illustration-sector {
      margin-top: -125px;
    }
    .gutterless {
      margin-top: 30px;
    }
    .logo{
      margin-left: auto;
      margin-right: auto;
    }
    .navigation-col{
      .navigation{
        margin-top: 0;
        .column-title{
          display: none;
        }
        .navigation-list{
          .navigation-item{
            margin-bottom: 20px;
          }
        }
      }
    }
    .contacts-col ,
    .addresses-col{
      display: none;
    }
    .follow-us-col{
      padding-bottom: 50px;
      .follow-us{
        .column-title{
          margin: 20px 0;
        }
      }
      .social-links{
        .social-link-item{
          .image{
            margin: 20px;
          }
          border: 0.5px solid $color-text-grayed-light;
          border-radius: 50%;
        }
      }
    }
    .external-book-links {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin: 0 auto;
      align-items: flex-start;
      margin-top: 14px;
    }
  }
}

@media (max-width: 491px) {
  .site-footer {
    .footer-illustration-sector {
      margin-top: 0;
    }
  }
}
