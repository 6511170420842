@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Cabin:400,400i,500,600,600i,700,700i);

@import 'styles/_biocant-variables';
@import 'styles/_mixins';
@import 'styles/_reset';
@import 'styles/_flexboxgrid';
@import 'styles/_typography';
@import 'styles/_animations';

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  @include font-smoothing();
}

* {
  box-sizing: border-box;
}

.relative {
  position: relative;
}

.app-shell {
  background-color: $color-background-light;
  overflow: hidden;

  &.freeze {
    height: 100vh;
  }
}

.page-wrapper {
  padding-top: 56px;
  background-color: $white;
  overflow: hidden;
  min-height: calc(100vh - 745px);
  >:first-child{
    animation-duration: 1s;
    transition-timing-function: ease-in;
    animation-fill-mode: both;
    animation-name: openPage;
  }
}

.ellipsis-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loading{
  img{
    height: 120px;
    width: 105px;
  }
}
@media only screen and (min-width: $sm-size) {
  .ellipsis-line-sm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: $lg-size) {
  .page-wrapper {
    padding-top: 70px;
    min-height: calc(100vh - 282px);
  }
}
